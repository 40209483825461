@import "~/frontend/scss/stage0";

$dark: #13355a;
$primary: #152341;
$secondary: #16aede;
$min-contrast-ratio: 2.5;

@import "~/frontend/scss/stage1";

// dropdown
$dropdown-dark-bg: $secondary;
$dropdown-dark-link-color: $light;
// navbar
$navbar-light-toggler-border-color: $light;
$navbar-dark-toggler-border-color: $dark;

@import "~/frontend/scss/stage2";
